import styled from 'styled-components';
import AppColors from '../../../styles/colors';

export const HomeBannerBox = styled.div`
  display: block;
  text-align: center;
  border-right: 2px solid #fff;
  @media (max-width: 1399px) {
    font-size: 20px;
    line-height: 32px;
  }
  @media (max-width: 767px) {
    max-width: 100%;
    border: none;
    font-size: 16px;
    line-height: 24px;
  }
  ::selection {
    color: #fff;
    background: #00a6e8;
  }
`;

export const PlusYellow = styled.img`
  position: absolute;
  top: 5px;
  right: 0;
  width: 11px;
  height: auto;
  border: 0;
  vertical-align: top;
`;
export const PlusBlue = styled.img`
  position: absolute;
  display: none;
  top: 5px;
  right: 0;
  width: 11px;
  height: auto;
  border: 0;
  vertical-align: top;
`;

export const HomeBannerBoxExploreInner = styled.div``;
export const HomeBannerBoxExplore = styled.a`
  display: block;
  height: 700px;
  padding: 100px 0 0 0;
  overflow: hidden;
  text-decoration: none;
  color: #00a6e8;
  text-align: center;
  background-size: cover !important;
  background: url(${(props) => props.$backgroundImage}) no-repeat center center;
  :hover {
    text-decoration: none;
    color: #f7991c;
    outline: none;
    ${HomeBannerBoxExploreInner} {
      transform: scale(1.15);
      transition: 0.25s ease-out;
    }
    p {
      color: #00539d;
      a {
        color: #00539d;
      }
    }
    h2 {
      margin-bottom: 25px;
    }
    h2:after {
      bottom: -57px;
      height: 35px;
    }
  }
  @media (max-width: 1799px) {
    height: 650px;
    padding-top: 115px;
  }
  @media (max-width: 1399px) {
    height: 520px;
    padding-top: 75px;
    :hover {
      h2:after {
        bottom: -58px;
      }
    }
  }
  @media (max-width: 991px) {
    height: 400px;
    padding-top: 50px;
  }
  @media (max-width: 767px) {
    height: 380px;
    padding-top: 35px;
    background-size: cover;
    :hover {
      h2:after {
        bottom: -52px;
      }
    }
  }
`;

export const HomeBannerBoxExploreInnerHeader = styled.h2`
  position: relative;
  margin: 0 0 35px 0;
  font-family: 'MrAlex', sans-serif;
  font-size: 58px;
  font-weight: bold !important;
  line-height: 58px;
  color: #00539d;
  @media (max-width: 1399px) {
    font-size: 50px;
    line-height: 50px;
  }
  @media (max-width: 991px) {
    font-size: 40px;
    line-height: 40px;
  }
  :after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: -20px;
    width: 100px;
    height: 4px;
    margin-left: auto;
    margin-right: auto;
    background: #ffc905;
    border-radius: 4px;
    transition: all 0.25s ease-out;
  }
`;

export const HomeBannerBoxExploreInnerParagraph = styled.p`
  display: inline-block;
  position: relative;
  font-family: 'MrAlex', arial, sans-serif;
  font-size: 20px;
  font-weight: bold;
  line-height: 20px;
  text-transform: uppercase;
  color: #ffc905;
  margin: 0 0 40px 0;
  padding: 0;
  text-align: center;
  a {
    color: #ffc905;
    text-decoration: none !important;
  }
  @media (max-width: 1399px) {
    margin: 0 0 30px 0;
  }
`;

export const HomeBannerBoxAccountInner = styled.div`
  display: inline-block;
  vertical-align: middle;
  font-family: 'MrAlex', arial, sans-serif;
  text-transform: uppercase;
  font-size: 24px;
  line-height: 24px;
  font-weight: bold;
  color: #fff;
  transition: all 0.25s ease-out;
  @media (max-width: 1399px) {
    font-size: 17px;
    line-height: 17px;
  }
  @media (max-width: 767px) {
    font-size: 18px;
    line-height: 18px;
  }
`;
export const HomeBannerBoxAccount = styled.a`
  display: block;
  position: fixed;
  bottom: 0;
  height: 60px;
  line-height: 60px;
  width: 33.33%;
  max-width: 640px;
  background: ${AppColors.DarkBlue};
  z-index: 99;
  border-right: ${(props) =>
    props.$scrollPosition > 0 ? '2px solid #00539d' : '2px solid #fff'};
  @media (max-width: 1399px) {
    height: 50px;
    line-height: 55px;
  }
  @media (max-width: 767px) {
    display: block;
    position: relative;
    width: 100%;
    z-index: 1;
    height: 50px;
    line-height: 52px;
    background: #00539d;
    text-decoration: none;
    transition: 0.25s ease-out;
    border-right: 0;
  }
  :hover {
    text-decoration: none;
    color: #f7991c;
    outline: none;
    ${HomeBannerBoxAccountInner} {
      color: #ffc905;
      transform: scale(1.07);
      transition: 0.25s ease-out;
    }
  }
`;

export const HomeBannerBoxAccountInnerImage = styled.img`
  width: 33px;
  height: auto;
  margin: -3px 15px 0 0;
  border: 0;
  vertical-align: top;
  @media (max-width: 767px) {
    margin: -7px 15px 0 0;
  }
`;
